import Vue from 'vue'
import Vuex from 'vuex'

// Store For Template Theme
import actions from './actions'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

/**
 * Admin Modules
 */
import auth from './storeModules/auth'
import users from './storeModules/users'
import contracts from './storeModules/contracts'
import insuranceTypes from './storeModules/insuranceType'
import insuranceProviders from './storeModules/insuranceProvider'
import insuranceProducts from './storeModules/insuranceProduct'
import currencies from './storeModules/currency'
import commissions from './storeModules/commission'
import paymentFrequencies from './storeModules/paymentFrequencies'
import roles from './storeModules/roles'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    users,
    contracts,
    insuranceTypes,
    insuranceProviders,
    insuranceProducts,
    currencies,
    commissions,
    paymentFrequencies,
    roles,
  },
  actions,
  strict: process.env.NODE_ENV !== 'production',
})
