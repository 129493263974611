// eslint-disable-next-line no-unused-vars
import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    managersList: [],
    agentsList: [],
    brokersList: [],
    leadsList: [],
    brokersLeadsList: [],
    adminUsersList: [],
    userData: null,
    isLoading: false,
    error: null,
  },
  getters: {
    managersList(state) {
      return state.managersList
    },
    agentsList(state) {
      return state.agentsList
    },
    brokersList(state) {
      return state.brokersList
    },
    leadsList(state) {
      return state.leadsList
    },
    brokersLeadsList(state) {
      return state.brokersLeadsList
    },
    adminUsersList(state) {
      return state.adminUsersList
    },
    userData(state) {
      return state.userData
    },
    isLoading(state) {
      return state.isLoading
    },
    error(state) {
      return state.error
    },
  },
  mutations: {
    SET_MANAGERS(state, managersList) {
      state.managersList = managersList
    },
    SET_AGENTS(state, agentsList) {
      state.agentsList = agentsList.sort((a, b) => {
        const nameA = a.name.toLowerCase()
        const nameB = b.name.toLowerCase()

        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    SET_BROKERS(state, brokersList) {
      state.brokersList = brokersList.sort((a, b) => {
        const aFirstChar = a.firstName.charAt(0)
        const bFirstChar = b.firstName.charAt(0)
        if (aFirstChar > bFirstChar) {
          return 1
        } if (aFirstChar < bFirstChar) {
          return -1
        }
        const aLastChar = a.lastName.charAt(0)
        const bLastChar = b.lastName.charAt(0)
        if (aLastChar > bLastChar) {
          return 1
        } if (aLastChar < bLastChar) {
          return -1
        }
        return 0
      })
    },
    SET_LEADS(state, leadsList) {
      state.leadsList = leadsList
    },
    SET_BROKERS_LEADS(state, brokersLeadsList) {
      state.brokersLeadsList = brokersLeadsList.sort((a, b) => {
        const nameA = a.full_name.toLowerCase()
        const nameB = b.full_name.toLowerCase()

        if (nameA < nameB) return -1
        if (nameA > nameB) return 1
        return 0
      })
    },
    SET_ADMIN_USERS(state, adminUsersList) {
      state.adminUsersList = adminUsersList
    },
    SET_USER(state, userData) {
      state.userData = userData
    },
    /* REPLACE_USER(state, userItem) {
      const userItemIndex = state.usersList.map(item => item.id).indexOf(userItem.id)
      Vue.set(state.usersList, userItemIndex, userItem)
    },
    GET_USER(state, index) {
      const userItemIndex = state.usersList.map(item => item.id).indexOf(index)
      state.userItem = state.usersList[userItemIndex]
    }, */
    SET_LOADING_STATUS(state, isLoading) {
      state.isLoading = isLoading
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    /* REMOVE_ROW(state, index) {
      const removeIndex = state.usersList.map(item => item.id).indexOf(index)
      state.usersList.splice(removeIndex, 1)
    }, */
  },
  actions: {
    deleteRow({ commit }, dispatchData) {
      commit('REMOVE_ROW', dispatchData.id)
    },
  },
}
