export default [
  {
    path: '/settings/users',
    name: 'adminUsers',
    component: () => import('@/views/pages/settings/users/AdminUsers'),
    meta: {
      pageTitle: 'Admin Users',
      resource: 'adminUsers',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/settings/users/add',
    name: 'addAdminUser',
    component: () => import('@/views/pages/settings/users/AddAdminUser'),
    meta: {
      pageTitle: 'Add User',
      resource: 'addAdminUsers',
      action: 'read',
      breadcrumb: [
        {
          text: 'Add',
          active: true,
        },
      ],
    },
  },
]
