export default [
  {
    path: '/commissions/create',
    name: 'create_commission',
    component: () => import('@/views/pages/commission/AddCommission'),
    meta: {
      pageTitle: 'Commission',
      resource: 'create_commission',
      action: 'read',
      breadcrumb: [
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/commissions/all',
    name: 'all_commissions',
    component: () => import('@/views/pages/commission/AllCommissions'),
    meta: {
      pageTitle: 'Commission',
      resource: 'commission',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
]
