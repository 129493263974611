export default [
  {
    path: '/users/managers',
    name: 'managers',
    component: () => import('@/views/pages/users/Managers'),
    meta: {
      resource: 'managers',
      action: 'read',
      pageTitle: 'Managers',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/agents',
    name: 'agents',
    component: () => import('@/views/pages/users/Agents'),
    meta: {
      pageTitle: 'Sales Advisors',
      resource: 'agents',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/brokers',
    name: 'brokers',
    component: () => import('@/views/pages/users/Brokers'),
    meta: {
      pageTitle: 'Brokers',
      resource: 'brokers',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/leads',
    name: 'leads',
    component: () => import('@/views/pages/users/leads/Leads'),
    meta: {
      pageTitle: 'Leads',
      resource: 'leads',
      action: 'read',
      breadcrumb: [
        {
          text: 'All',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users/leads/calendar',
    name: 'meetingCalendar',
    component: () => import('@/views/pages/users/leads/calendar/Calendar'),
    meta: {
      pageTitle: 'Meeting Calendar',
      resource: 'leads',
      action: 'read',
      breadcrumb: [
        {
          text: 'Calendar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user/:id',
    name: 'user_data',
    component: () => import('@/views/pages/users/users_view/ViewUser'),
    meta: {
      pageTitle: 'User Profile',
      resource: 'viewUser',
      action: 'read',
    },
  },
]
